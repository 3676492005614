import axios from 'axios';
import { useCurrentOrganization } from 'src/store/organization';
import { v4 as uuidv4 } from 'uuid';
import { trackSegmentEvent } from './segment';

const queryParams = (journeyIds) =>
  new URLSearchParams({
    journey_ids: journeyIds.join(','),
  });

const getWorkspaceId = () => {
  const { currentWorkspace } = useCurrentOrganization.getState();
  return currentWorkspace ? currentWorkspace.id : null;
};

function errorHandler(axiosError) {
  return Promise.reject(axiosError.response?.data);
}

export const journeyPostJson = (url, body = {}, config = {}) => {
  return axios
    .post(url, body, config)
    .then((x) => x.data)
    .catch(errorHandler);
};

export const journeyGetJson = (url, params = {}) =>
  axios
    .get(url, { params })
    .then((x) => x.data)
    .catch(errorHandler);

export const journeyPutJson = (url, body = {}) =>
  axios
    .put(url, body)
    .then((x) => x.data)
    .catch(errorHandler);

export const journeyDeleteJson = (url) =>
  axios
    .delete(url)
    .then((x) => x.data)
    .catch(errorHandler);

function apiUpdateUser(params) {
  const apiUrl = `/api/current_user`;

  return journeyPutJson(apiUrl, params);
}

function apiUpdateUserPassword(params) {
  const apiUrl = `/api/current_user/password`;

  return journeyPutJson(apiUrl, params);
}

function apiAwsGetUploadCredentials(object_name) {
  const apiUrl = `/api/s3/get-upload-credentials`;

  const params = {
    object_name: object_name,
  };

  return journeyPostJson(apiUrl, params);
}

const apiGetProspectLogo = (domain, slug) =>
  journeyGetJson(`/api/prospect-logo/${domain}`, {
    organization_slug: slug,
  });

const apiAddProspectLogo = (domain, url) => {
  const apiUrl = `/api/prospect-logo/${domain}`;

  const params = {
    url: url,
  };

  return journeyPostJson(apiUrl, params);
};

const apiGetWorkspaces = (orgId) => journeyGetJson(`/api/organizations/${orgId}/workspaces`, { sort: 'name' });

const apiGetWorkspace = (workspaceId) => journeyGetJson(`/api/workspaces/${workspaceId}`);

const apiCreateWorkspace = (orgId, params) => journeyPostJson(`/api/organizations/${orgId}/workspaces`, params);

const apiUpdateWorkspace = (workspaceId, params = {}) => journeyPutJson(`/api/workspaces/${workspaceId}`, params);

const apiDeleteWorkspace = (workspaceId) => journeyDeleteJson(`/api/workspaces/${workspaceId}`);

const apiDeactivateOrganization = (organizationId) =>
  journeyPostJson(`/api/organizations/${organizationId}/deactivate`);

const apiGetWorkspaceJourneys = (
  workspaceId,
  { page = 1, searchStr = null, include_engagements = true, include_follows = true, include_steps = true }
) =>
  journeyGetJson(`/api/workspaces/${workspaceId}/journeys`, {
    search_str: searchStr,
    page: page,
    include_engagements: include_engagements,
    include_follows: include_follows,
    include_steps: include_steps,
  });

const apiCreateNode = (organizationId, workspaceId, params) => {
  if (workspaceId) {
    return journeyPostJson(`/api/workspaces/${workspaceId}/journey-nodes`, params);
  } else {
    return journeyPostJson(`/api/organizations/${organizationId}/journey-nodes`, params);
  }
};

const apiUpdateOrganizationSettings = (organizationId, params) =>
  journeyPostJson(`/api/organizations/${organizationId}/settings`, params);

const apiCreateOrganisation = ({ name, slug, url, rootOrgId }) =>
  journeyPostJson('/api/organizations', { name, slug, url, rootOrgId });

const apiUpdateNode = (nodeId, params) => journeyPutJson(`/api/journey-nodes/${nodeId}`, params);

const apiUpdateStepNode = (stepID, nodeID, params) =>
  journeyPutJson(`/api/journey-steps/${stepID}/journey-nodes/${nodeID}`, params);

function apiCreateOrUpdateNode(organizationId, workspaceId, node_type, nodeId = null, params = {}) {
  if (nodeId)
    return apiUpdateNode(nodeId, {
      node_type,
      ...params,
    });

  return apiCreateNode(organizationId, workspaceId, {
    node_type,
    ...params,
  });
}

const apiSendSessionEventsToServer = ( params={}) => {
  journeyPostJson(`https://session.journey.io/session`, params)
  .then(response => {
  })
  .catch(error => {
    console.error("Failed to send events", error);
  });
};

const apiGetSessionReplay = (organizationId,replayId) => journeyGetJson(`/api/organizations/${organizationId}/session-replay/${replayId}`);


const apiGetAllSessions = (organizationId,params) => journeyGetJson(`/api/organizations/${organizationId}/journeys/session-replays`,params);

function apiUpdateDocumentContent(documentId, content) {
  const apiUrl = `/api/documents/${documentId}`;

  return journeyPutJson(apiUrl, { content });
}

function apiUpdateDocumentBlock(documentId, blockId, blockContent) {
  const apiUrl = `/api/documents/${documentId}/blocks/${blockId}`;

  return journeyPutJson(apiUrl, { content: blockContent });
}

function apiCreateDocumentBlock(documentId, previousBlockId, blockContent = '') {
  const apiUrl = `/api/documents/${documentId}/blocks`;

  const params = {
    previous_block_id: previousBlockId,
    content: blockContent,
  };

  return journeyPostJson(apiUrl, params);
}

function apiDeleteDocumentBlock(documentId, blockId) {
  const apiUrl = `/api/documents/${documentId}/blocks/${blockId}`;

  return journeyDeleteJson(apiUrl);
}

function apiReorderDocumentBlock(documentId, blockId, previousBlockId) {
  const apiUrl = `/api/documents/${documentId}/blocks/${blockId}/reorder`;

  const params = {
    previous_block_id: previousBlockId,
  };

  return journeyPostJson(apiUrl, params);
}

const apiGetAllJourneysQuickSearch = (orgId, { page = 1, per_page = 12, searchStr = '' }) =>
  journeyGetJson(`/api/organizations/${orgId}/journeys/quick-search`, {
    ...(searchStr ? { search_str: searchStr } : {}),
    page,
    per_page,
  });

const apiGetAllJourneys = (
  orgId,
  {
    page = 1,
    per_page = 12,
    list = '',
    searchStr = '',
    orderBy = '',
    sortName = '',
    nodeId = null,
    include_engagements = false,
    include_follows = true,
    include_steps = true,
    search_all_workspaces = false,
    tag_ids = null,
  }
) =>
  journeyGetJson(`/api/organizations/${orgId}/journeys`, {
    ...(searchStr ? { search_str: searchStr } : {}),
    ...(orderBy ? { order_by: orderBy } : {}),
    ...(sortName ? { sort_name: sortName } : {}),
    ...(tag_ids ? { tag_ids: tag_ids } : {}),
    node_id: nodeId,
    list,
    page,
    per_page,
    include_engagements,
    include_follows,
    include_steps,
    ...(search_all_workspaces ? {} : { workspace_id: getWorkspaceId() }),
  });

const apiGetTopJourneysInsights = (orgId) => journeyGetJson(`/api/organizations/${orgId}/top-insights`);

// TAGS
const apiGetAllOrganizationTags = (orgId) => {
  return journeyGetJson(`/api/organizations/${orgId}/tags`);
};
const apiCreateOrganizationTag = (orgId, tagName) => {
  const body = {
    name: tagName,
  };
  return journeyPostJson(`/api/organizations/${orgId}/tags`, body);
};
const apiAddTagToJourney = (journeyId, tagId) => {
  return journeyPostJson(`/api/journeys/${journeyId}/tags/${tagId}`);
};
const apiRemoveTagFromJourney = (journeyId, tagId) => {
  return journeyDeleteJson(`/api/journeys/${journeyId}/tags/${tagId}`);
};
const apiGetJourneyTags = (journeyId) => {
  return journeyGetJson(`/api/journey/${journeyId}/tags`);
};
const apiGetJourneysByTags = (orgId, tags) => {
  console.log('😠😠😠😠😠', tags);
  return journeyGetJson(`/api/organizations/${orgId}/journeys?tag_ids=${tags}`);
  // .then((success) => {
  //   console.log('🤶', success);
  //   return success;
  // })
  // .catch((error) => {
  //   console.log('👨‍🍳', error);
  //   return { journeys: [], pages: 0, page: 1, per_page: 16, total: 0 };
  // });
};

function apiGetJourneyEngagements(
  journey_id = null,
  include_interactions = true,
  session_uuid = undefined,
  time_period = undefined
) {
  const { currentOrganization } = useCurrentOrganization.getState();
  const apiUrl = `/api/organizations/${currentOrganization.id}/engagements`;

  const params = {
    ...(journey_id && { journey_id: journey_id }),
    ...(include_interactions && { include_interactions: include_interactions }),
    ...(session_uuid && { session_uuid: session_uuid }),
    ...(time_period && { time_period }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyOverviewEngagements(
  journeyIds,
  include_interactions = true,
  session_uuid = undefined,
  time_period = undefined
) {
  const { currentOrganization } = useCurrentOrganization.getState();
  const apiUrl = `/api/organizations/${currentOrganization.id}/overall-engagements?${
    journeyIds ? queryParams(journeyIds).toString() : ''
  }`;

  const params = {
    // ...(journey_id && { journey_id: journey_id }),
    // ...(include_interactions && { include_interactions: include_interactions }),
    // ...(session_uuid && { session_uuid: session_uuid }),
    // ...(time_period && { time_period }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetAllJourneysInsights(orgId, params = {}) {
  const apiUrl = `/api/organizations/${orgId}/insights/journeys`;

  return journeyGetJson(apiUrl, params);
}

function apiGetAllJourneysOverviewInsights(orgId, journeyIds, params = {}) {
  const apiUrl = `/api/organizations/${orgId}/insights-overview/journeys?${
    journeyIds ? queryParams(journeyIds).toString() : ''
  }`;

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyInsightsByContent(
  journey_uuid,
  session_uuid = undefined,
  disable_session_grouping = false,
  time_period = undefined
) {
  const apiUrl = `/api/insights/journeys/${journey_uuid}`;

  const params = {
    ...(session_uuid && { session_uuid: session_uuid }),
    ...(disable_session_grouping && { disable_session_grouping }),
    ...(time_period && { time_period }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyDocumentInsights(
  journey_uuid,
  node_ids,
  session_uuid = undefined,
  disable_session_grouping = false,
  time_period = undefined
) {
  const apiUrl = `/api/insights/journeys/${journey_uuid}/document`;

  const params = {
    ...(session_uuid && { session_uuid: session_uuid }),
    ...(disable_session_grouping && { disable_session_grouping }),
    ...(time_period && { time_period }),
    ...(node_ids && node_ids.length > 0 && { node_ids: node_ids.join(',') }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyInsights(journey_uuid, page = 1, filters = {}) {
  const apiUrl = `/api/journeys/${journey_uuid}/insights`;
  const params = {
    page: page,
    ...filters,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyChatbotInsights(journey_uuid, page = 1, filters = {}) {
  const apiUrl = `/api/journeys/${journey_uuid}/chatbot-insights`;
  const params = {
    page: page,
    ...filters,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetContentInsights(organization_id, filters = {}) {
  const apiUrl = `/api/organizations/${organization_id}/contents/insights`;
  const params = {
    ...filters,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetContentDocumentInsights(organization_id, content_uuid) {
  const apiUrl = `/api/organizations/${organization_id}/contents/${content_uuid}/document-insights`;

  return journeyGetJson(apiUrl);
}

function apiSearchJourneyVisitors(journey_uuid, email) {
  const apiUrl = `/api/journeys/${journey_uuid}/visitors`;
  const params = {
    email: email,
  };

  return journeyGetJson(apiUrl, params);
}

function apiCreateJourneyAlias(journey_uuid, params) {
  const apiUrl = `/api/journeys/${journey_uuid}/aliases`;

  return journeyPostJson(apiUrl, params);
}

function apiGetJourneyAliases(journey_uuid) {
  const apiUrl = `/api/journeys/${journey_uuid}/aliases`;

  return journeyGetJson(apiUrl, {});
}

function apiUpdateJourneyAlias(alias_uuid, params) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}`;

  return journeyPutJson(apiUrl, params);
}

function apiDeleteJourneyAlias(alias_uuid) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}`;

  return journeyDeleteJson(apiUrl);
}

function apiUpdateJourneyThumbnail(journey_uuid, thumbnail_url) {
  const apiUrl = `/api/journeys/${journey_uuid}/thumbnail`;

  return journeyPutJson(apiUrl, { thumbnail_url });
}

const apiGetThumbnailEmbedUrl = (journey_uuid) => {
  const apiUrl = `/api/journeys/${journey_uuid}/thumbnail/embed`;
  return journeyGetJson(apiUrl);
};

function apiGetJourneyBySlug(org_slug, journey_slug, otherParams = {}) {
  const apiUrl = `/api/journeys`;

  const params = {
    slug: journey_slug,
    organization: org_slug,
    ...otherParams,
  };

  return journeyGetJson(apiUrl, params);
}

function apiCreateBlankJourney(orgId, workspaceId = null, name = null) {
  const uuid = uuidv4().replace(/-/g, '').substring(0, 6);

  return apiCreateJourney(orgId, workspaceId, {
    name: name || `Untitled Journey`,
    slug: `draft-journey-${uuid}`,
  });
}

function apiCreateNeueBlankJourney(orgId, workspaceId = null, params = {}) {
  const {
    name,
    duplicate_journey_uuid,
    alias_slug,
    seller_domain,
    recipient_domain,
    recipient_logo_url,
    recipient_name,
    precreated_sections,
    data,
  } = params;

  const requestParams = {
    name: name || `Untitled Journey`,
    data: { neue: true, ...data },
    ...(duplicate_journey_uuid && { duplicate_journey_uuid }),
    ...(alias_slug && { alias_slug }),
    ...(recipient_domain && { recipient_domain }),
    ...(recipient_logo_url && { recipient_logo_url }),
    ...(recipient_name && { recipient_name }),
    ...(precreated_sections && { precreated_sections }),
    ...(seller_domain && { seller_domain }),
  };

  return apiCreateJourney(orgId, workspaceId, requestParams);
}

const apiGetJourney = (uuid, params = {}) => journeyGetJson(`/api/journeys/${uuid}`, params);

const apiGetTagsByJourney = (uuid, params = {}) => journeyGetJson(`/api/journey/${uuid}/tags`, params);

const apiPostJourneyInteractionOpen = (uuid) =>
  journeyPostJson(`/api/journeys/${uuid}/interaction`, { interaction_type: 'open' });

const apiGetJourneyByAlias = (uuid, params = {}) => journeyGetJson(`/api/journey-aliases/${uuid}/journey`, params);

const apiCreateJourney = (orgId, workspaceId, params = {}) => {
  if (workspaceId) {
    return journeyPostJson(`/api/workspaces/${workspaceId}/journeys`, params);
  } else {
    return journeyPostJson(`/api/organizations/${orgId}/journeys`, params);
  }
};

const apiUpdateJourney = (uuid, params = {}) => journeyPutJson(`/api/journeys/${uuid}`, params);

const apiFollowJourney = (uuid) => journeyPutJson(`/api/journeys/${uuid}/follow`);

const apiUnfollowJourney = (uuid) => journeyPutJson(`/api/journeys/${uuid}/unfollow`);

const apiDeleteJourney = (uuid) => journeyDeleteJson(`/api/journeys/${uuid}`);
const apiBulkDeleteJourneys = (journeyUuids) =>
  journeyPostJson('/api/journeys/bulk-delete', { journey_uuids: journeyUuids });

const apiDuplicateJourney = (uuid, params = {}) => journeyPostJson(`/api/journeys/${uuid}/duplicate`, params);

const apiGetJourneyThumbnailUrl = (uuid, params = {}) => journeyGetJson(`/api/journeys/${uuid}/thumbnail`, params);

const apiGetAllNodes = (orgId, params = {}) => journeyGetJson(`/api/organizations/${orgId}/journey-nodes`, params);

const apiGetNode = (nodeId, params = {}) => journeyGetJson(`/api/journey-nodes/${nodeId}`, params);

const apiGetAllContents = (orgId, params = {}) => journeyGetJson(`/api/organizations/${orgId}/contents`, params);

const apiCreateContent = (orgId, params = {}) => journeyPostJson(`/api/organizations/${orgId}/contents`, params);

const apiToggleContentLibrary = (contentUuid, shouldShowLibrary) => {
  const apiUrl = `/api/contents/${contentUuid}/library`;

  const params = {
    should_show_library: shouldShowLibrary,
  };

  return journeyPutJson(apiUrl, params);
};

const apiUpdateContent = (id, params = {}) => journeyPutJson(`/api/contents/${id}`, params);

const apiDeleteContent = (id) => journeyDeleteJson(`/api/contents/${id}`);

const apiDeleteContentByUuid = (uuid) => journeyDeleteJson(`/api/contents/uuid/${uuid}`);

const apiBulkDeleteContents = (contentIds) => journeyPostJson('/api/contents/bulk-delete', { content_ids: contentIds });

const apiGetContent = (id) => journeyGetJson(`/api/contents/${id}`);

const apiGetContentTags = (contentId) => journeyGetJson(`/api/contents/${contentId}/tags`);
const apiAddTagToContent = (contentId, tagId) => journeyPostJson(`/api/contents/${contentId}/tags/${tagId}`);
const apiRemoveTagFromContent = (contentId, tagId) => journeyDeleteJson(`/api/contents/${contentId}/tags/${tagId}`);

const apiGetContentUsage = (id) => journeyGetJson(`/api/contents/${id}/usage`);

const apiUploadContent = (url) => journeyPostJson(`/api/contents/upload`, { url });

const apiGenerateContentTranscript = (params = {}) => journeyPostJson(`/api/contents/transcript/generate`, params);

const apiUpdateNodeContent = (node_id, params = {}) => journeyPutJson(`/api/journey-nodes/${node_id}/content`, params);

const apiGetRecentNodes = (orgId, params = {}) =>
  journeyGetJson(`/api/organizations/${orgId}/journey-nodes/recent`, params);

function apiGetPersonalizedVideos(uuid, page = 1, search_str = null) {
  const apiUrl = `/api/journeys/${uuid}/personalized-videos`;
  const params = {
    page: page,
    ...(search_str && { search_str: search_str }),
  };
  return journeyGetJson(apiUrl, params);
}

function apiAddNodeToJourney(node_uuid, step_id, previous_node_uuid = null, stepNodeParams = {}) {
  if (step_id === null) {
    return Promise.reject('Step ID is required');
  }
  const params = {
    node_uuid: node_uuid,
    ...(previous_node_uuid && { previous_node_uuid: previous_node_uuid }),
    ...stepNodeParams,
  };

  const apiUrl = `/api/journey-steps/${step_id}/add-node`;
  return journeyPostJson(apiUrl, params);
}

function apiRemoveNodeFromJourney(node_uuid, step_id, journey_uuid) {
  const params = {
    node_uuid,
    step_id,
    journey_uuid,
  };

  const apiUrl = `/api/journey-steps/${step_id}/remove-node`;
  return journeyPostJson(apiUrl, params);
}

function apiDeleteJourneyNode(nodeId) {
  const apiUrl = `/api/journey-nodes/${nodeId}`;
  return journeyDeleteJson(apiUrl);
}

function apiReplaceNodeInJourney(journey_uuid, step_id, old_node_uuid, new_node_uuid) {
  const params = {
    journey_uuid: journey_uuid,
    old_node_uuid: old_node_uuid,
    new_node_uuid: new_node_uuid,
  };

  const apiUrl = `/api/journey-steps/${step_id}/replace-node`;
  return journeyPostJson(apiUrl, params);
}

function apiRefreshNodeThumbnail(node_id) {
  const apiUrl = `/api/journey-nodes/${node_id}/thumbnail`;

  return journeyPostJson(apiUrl);
}

function apiAddNodeToSynced(node_id, name) {
  return journeyPostJson(`/api/content/${node_id}/synced`, {
    name: name,
  });
}

function apiDetachNodeFromSynced(node_id, workspace_id) {
  return journeyPostJson(`/api/content/${node_id}/synced-detach`, {
    workspace_id: workspace_id,
  });
}

function apiUnlockJourney(alias_uuid, params) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}/unlock`;
  return journeyPostJson(apiUrl, params);
}

function apiRequestAccessToJourney(alias_uuid, params) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}/request-access`;
  return journeyPostJson(apiUrl, params);
}

function apiSendJourney(alias_uuid, params) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}/send`;
  return journeyPostJson(apiUrl, params);
}

function apiCreateEmailVerification(alias_uuid, email) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}/verification`;
  const params = { email: email };
  return journeyPostJson(apiUrl, params);
}

function apiUnlockJourneyByToken(token) {
  const apiUrl = `/api/email-verification/${token}/verify`;
  return journeyPostJson(apiUrl);
}

function apiVerifyEmail(alias_uuid, access_code) {
  const apiUrl = `/api/journey-aliases/${alias_uuid}/verify`;
  const params = { access_code: access_code };
  return journeyPostJson(apiUrl, params);
}

function apiCreateStep(journey_uuid, stepParams = {}) {
  const params = {
    journey_uuid: journey_uuid,
    ...stepParams,
  };

  const apiUrl = `/api/journey-steps`;

  return journeyPostJson(apiUrl, params);
}

function apiDeleteStep(step_id) {
  const apiUrl = `/api/journey-steps/${step_id}/delete`;

  return journeyPostJson(apiUrl);
}

function apiUpdateStep(step_id, params) {
  const apiUrl = `/api/journey-steps/${step_id}`;

  return journeyPostJson(apiUrl, params);
}

function apiReorderStep(step_id, previous_step_id, otherParams = {}) {
  const apiUrl = `/api/journey-steps/${step_id}/reorder`;

  const params = {
    ...(previous_step_id && { previous_step_id: previous_step_id }),
    ...otherParams,
  };

  return journeyPostJson(apiUrl, params);
}

function apiGetCurrentUser() {
  const apiUrl = `/api/current_user`;
  return journeyGetJson(apiUrl);
}

function apiGetUserChangelog() {
  const apiUrl = `/api/get_user_changelog`;
  return journeyGetJson(apiUrl);
}

function apiGetPlayerCommsToken(slug, suggestedCommenterUserUUID) {
  const apiUrl = `/api/player-comms/${slug}/token`;
  return journeyPostJson(apiUrl, { suggested_user_uuid: suggestedCommenterUserUUID });
}

function apiGetCommsInbox(queryParams = '') {
  const apiUrl = `/api/player-comms/inbox${queryParams}`;
  return journeyGetJson(apiUrl);
}

function apiGetCommsInboxUnreadCount(queryParams = '') {
  const apiUrl = `/api/player-comms/inbox/unread-channels-count${queryParams}`;
  return journeyGetJson(apiUrl);
}

function apiGetCommsInboxToken() {
  const apiUrl = '/api/player-comms/inbox/token';
  return journeyPostJson(apiUrl);
}

function apiGenerateCommsTokenByUserInfo(slug, name, email) {
  const apiUrl = `/api/player-comms/${slug}/token_for_identified`;
  return journeyPostJson(apiUrl, { name, email });
}

function apiCreateCommsChannel(slug, node_uuid, user_id, org_id = '') {
  let apiUrl = '/api/player-comms';
  if (org_id) {
    apiUrl += `/${org_id}`;
  }
  apiUrl += `/${slug}/${node_uuid}/channel`;
  return journeyPostJson(apiUrl, { user_id });
}

function apiUnsubscribeComments(queryParams) {
  const apiUrl = `/comments-unsubscribe${queryParams}`;
  return journeyPostJson(apiUrl);
}

function apiGetCommsChannel(slug) {
  const apiUrl = `/api/player-comms/${slug}/channels`;
  return journeyGetJson(apiUrl);
}

function apiRevokeUser(organizationId, userId) {
  const apiUrl = `/api/organizations/${organizationId}/users/${userId}`;
  return journeyDeleteJson(apiUrl);
}

function apiGetOrganizations() {
  const apiUrl = `/api/current_user/organizations`;
  return journeyGetJson(apiUrl);
}

function apiUpdateOrganizationBranding(organization_id, params) {
  const apiUrl = `/api/organizations/${organization_id}/branding`;
  return journeyPostJson(apiUrl, params);
}

function apiUpdateOrganization(organization_id, params) {
  const apiUrl = `/api/organizations/${organization_id}`;
  return journeyPostJson(apiUrl, params);
}

function apiSwitchOrganization(organization_id) {
  const apiUrl = `/api/organizations/${organization_id}/switch`;
  return journeyPostJson(apiUrl);
}

function apiSwitchOrganizationBySlug(slug) {
  const apiUrl = `/api/organizations/slug/${slug}/switch`;
  return journeyPostJson(apiUrl);
}

function apiGetOrganizationUsers(organization_id) {
  const apiUrl = `/api/organizations/${organization_id}/users`;
  return journeyGetJson(apiUrl);
}

function apiGetOrganizationInvites(organization_id) {
  const apiUrl = `/api/organizations/${organization_id}/invites`;
  return journeyGetJson(apiUrl);
}

function apiSendOrganizationInvite(organization_id, email, otherParams = {}) {
  const params = { email: email, ...otherParams };
  const apiUrl = `/api/organizations/${organization_id}/invite`;

  return journeyPostJson(apiUrl, params);
}

function apiResendOrganizationInvite(organization_id, uuid) {
  const params = { uuid };
  const apiUrl = `/api/organizations/${organization_id}/invite/resend`;

  return journeyPostJson(apiUrl, params);
}

function apiDeleteOrganizationInvite(organization_id, token) {
  const params = { token: token };
  const apiUrl = `/api/organizations/${organization_id}/invites/delete`;

  return journeyPostJson(apiUrl, params);
}

function apiGetUserPersonas() {
  const apiUrl = `/api/user-personas`;

  return journeyGetJson(apiUrl);
}

function apiGetSuggestedTemplates() {
  const apiUrl = `/api/suggested-templates`;

  return journeyGetJson(apiUrl);
}

function apiGetJourneyTemplate(templateCode) {
  const apiUrl = `/api/journey-template`;

  const params = {
    template_code: templateCode,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyTemplates(codes = []) {
  const apiUrl = `/api/journey-templates`;

  const params = {
    codes: codes,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetJourneyTemplateCategories(category = null) {
  const apiUrl = `/api/journey-template-categories`;
  const params = {
    ...(category && { category: category }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetOrganizationTemplates(params) {
  const apiUrl = `/api/organizations/${useCurrentOrganization.getState().currentOrganization.id}/templates`;

  return journeyGetJson(apiUrl, params);
}

function apiCreateOrganizationTemplateFromJourney(organization_id, journey_id, name, description = '') {
  const apiUrl = `/api/organizations/${organization_id}/templates`;

  const params = {
    journey_id: journey_id,
    name: name,
    description: description,
  };

  return journeyPostJson(apiUrl, params);
}

function apiDeleteOrganizationTemplate(organization_id, template_id) {
  const apiUrl = `/api/organizations/${organization_id}/templates/${template_id}`;
  return journeyDeleteJson(apiUrl);
}

function apiCreateJourneyFromTemplate(organizationId, workspaceId, templateId, journeyRecipient = null) {
  if (workspaceId) {
    return journeyPostJson(`/api/workspaces/${workspaceId}/journey-templates/${templateId}/journeys`, journeyRecipient);
  } else {
    return journeyPostJson(`/api/organizations/${organizationId}/journey-templates/${templateId}/journeys`);
  }
}

function apiGetDestinations(search_str = '') {
  const apiUrl = `/api/destinations`;

  const params = {
    ...(search_str && { search_str: search_str }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiOrganizationSmartSearch(organization_id, search_str = '', page = 1) {
  const apiUrl = `/api/organizations/${organization_id}/smart-search`;

  const params = {
    ...(search_str && { search_str: search_str }),
    ...(page && { page: page }),
  };

  return journeyGetJson(apiUrl, params);
}

function apiOauthAuthorize(provider, tokenParams) {
  const apiUrl = `/oauth/${provider}/authorize`;

  return journeyGetJson(apiUrl, tokenParams);
}

function apiGetUserIntegrations() {
  return journeyGetJson(`/api/integrations`);
}

function apiGetUserAccessTokens(provider) {
  const apiUrl = `/api/integrations/access-tokens`;
  const params = {
    provider: provider,
  };

  return journeyGetJson(apiUrl, params);
}

function apiRevokeAccessToken(provider, token_id) {
  const apiUrl = `/oauth/${provider}/revoke-token`;
  const params = {
    token_id: token_id,
  };

  return journeyPostJson(apiUrl, params);
}

function apiGetUserApiKeys(provider) {
  const apiUrl = `/api/integrations/api-keys`;
  const params = {
    provider: provider,
  };

  return journeyGetJson(apiUrl, params);
}

function apiCreateUserApiKey(provider, api_key) {
  const apiUrl = `/api/integrations/api-keys`;
  const params = {
    provider: provider,
    api_key: api_key,
  };

  return journeyPostJson(apiUrl, params);
}

async function apiHubSpotSync(aliasUuid, data) {
  const apiUrl = `/api/journey-aliases/${aliasUuid}/sync-with-hubspot`;

  await apiUpdateJourneyAlias(aliasUuid, data);

  return journeyPostJson(apiUrl);
}

async function apiCreateMergeFields(aliasUuid, data) {
  const apiUrl = `/api/journey-aliases/${aliasUuid}/merge-fields`;

  return journeyPostJson(apiUrl, data);
}

function apiUpdateUserApiKey(api_key_id, api_key) {
  const apiUrl = `/api/integrations/api-keys/${api_key_id}`;
  const params = {
    api_key: api_key,
  };

  return journeyPutJson(apiUrl, params);
}

function apiDeleteUserApiKey(api_key_id) {
  const apiUrl = `/api/integrations/api-keys/${api_key_id}`;

  return journeyDeleteJson(apiUrl);
}

function apiGetOrganizationParagonToken() {
  const apiUrl = `/api/integrations/paragon-token`;

  return journeyGetJson(apiUrl);
}

function apiGetUserIntegrationByProvider(provider) {
  const apiUrl = `/api/integrations`;
  const params = {
    provider: provider,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetPublicApiKeys(organizationSlug, provider) {
  const apiUrl = `/api/integrations/public-api-keys`;
  const params = {
    provider: provider,
    org_slug: organizationSlug,
  };

  return journeyGetJson(apiUrl, params);
}

function apiGetGongObjects(objectPath, params = {}) {
  const apiUrl = `/api/integrations/gong/${objectPath}`;

  if (objectPath.includes('extensive')) {
    return journeyPostJson(apiUrl, params);
  }

  return journeyGetJson(apiUrl, params);
}

function apiGetNotionDoc(notionId) {
  const notionWorkerHost = 'https://notion-cloudflare-worker.jny-workers.workers.dev';
  const apiUrl = `${notionWorkerHost}/v1/page/${notionId}`;

  return journeyGetJson(apiUrl);
}

function apiGetNotionPublicPageData(notionId) {
  const apiUrl = `/api/notion/${notionId}/public-page-data`;

  return journeyGetJson(apiUrl);
}

function apiGetEmbedDetailsFromUrl(url) {
  const apiUrl = `/api/embed/check-url`;
  const params = { url: url };

  return journeyGetJson(apiUrl, params);
}

function apiExportJourneyEmails(journeyId) {
  const apiUrl = `/api/journeys/${journeyId}/export-emails`;

  return journeyPostJson(apiUrl);
}

function apiAddOrganizationAction(organizationId, action_type, unique = false) {
  const apiUrl = `/api/organizations/${organizationId}/actions`;

  const params = {
    action_type,
    unique,
  };

  return journeyPostJson(apiUrl, params);
}

function apiAddUserAction(action_type) {
  const apiUrl = `/api/current_user/actions`;

  const params = {
    action_type,
  };

  return journeyPostJson(apiUrl, params);
}

function apiGetUserActions() {
  const apiUrl = `/api/current_user/actions`;

  return journeyGetJson(apiUrl);
}

function apiCreateNodeAnnotation(nodeId, params) {
  const apiUrl = `/api/journey-nodes/${nodeId}/annotations`;

  return journeyPostJson(apiUrl, params);
}

function apiUpdateNodeAnnotation(annotationId, params) {
  const apiUrl = `/api/annotations/${annotationId}`;

  return journeyPutJson(apiUrl, params);
}

function apiDeleteNodeAnnotation(annotationId) {
  const apiUrl = `/api/annotations/${annotationId}`;

  return journeyDeleteJson(apiUrl);
}

function apiCreateVideoAsset(orgId, video_uuid, video_name, url, content_type) {
  const apiUrl = `/api/organizations/${orgId}/video`;

  const params = {
    video_uuid,
    video_name,
    url,
    content_type,
  };

  return journeyPostJson(apiUrl, params);
}
const apiGetSubscription = (organizationId) => journeyGetJson(`/api/organization/${organizationId}/subscription`);

const apiGetUserJourneys = (organizationId, userId) => journeyGetJson(`/api/journeys/${organizationId}/${userId}`);

const apiGetInvoices = (organizationId) => journeyGetJson(`/api/organization/${organizationId}/invoices`);

const apiCancelSubscription = (organizationId) =>
  journeyPostJson(`/api/organization/${organizationId}/subscription/cancel`);

const apiDowngradeSubscription = (organizationId) =>
  journeyPostJson(`/api/organization/${organizationId}/subscription/downgrade`);

const apiSubscribeMonthly = (organizationId, params = { method: 'year', plan: 'starter' }) => {
  let tolt_referral = null;
  try {
    tolt_referral = window.tolt_referral;
    params = {
      ...params,
      referral: tolt_referral,
    };
  } catch (err) {
    trackSegmentEvent('Tolt Referral Absent', { type: 'Checkout session' });
  }
  return journeyPostJson(`/api/organization/${organizationId}/subscribe`, params);
};

const apiCheckIfNdaSigned = (aliasUUID, email) =>
  journeyGetJson(`/api/journey-aliases/${aliasUUID}/nda/check`, {
    email: email,
  });

const apiSignNda = (aliasUUID, email, signature, fullName, companyName) =>
  journeyPostJson(`/api/journey-aliases/${aliasUUID}/nda/sign`, {
    email: email,
    signature: signature,
    full_name: fullName,
    company_name: companyName,
  });

const apiGetAllNdaSignatures = (aliasUUID) => journeyGetJson(`/api/journey-aliases/${aliasUUID}/nda/signatures`);

function apiGetMutualActionPlan(uuid) {
  const apiUrl = `/api/mutual-action-plans/${uuid}`;

  return journeyGetJson(apiUrl);
}

function apiCreateMutualActionPlan(uuid) {
  const apiUrl = `/api/mutual-action-plans`;
  return journeyPostJson(apiUrl, { uuid });
}

function apiUpdateMutualActionPlan(uuid, params) {
  const apiUrl = `/api/mutual-action-plans/${uuid}`;
  return journeyPutJson(apiUrl, params);
}

function apiCreateMutualActionPlanItem(uuid, params) {
  const apiUrl = `/api/mutual-action-plans/${uuid}/items`;
  return journeyPostJson(apiUrl, params);
}

function apiUpdateMutualActionPlanItemCompleted(uuid, mutualActionPlanItemId, completed) {
  const apiUrl = `/api/mutual-action-plans/${uuid}/items/${mutualActionPlanItemId}/completed`;
  return journeyPutJson(apiUrl, { completed });
}

function apiUpdateMutualActionPlanItem(uuid, mutualActionPlanItemId, params) {
  const apiUrl = `/api/mutual-action-plans/${uuid}/items/${mutualActionPlanItemId}`;
  return journeyPutJson(apiUrl, params);
}

function apiDeleteMutualActionPlanItem(uuid, mutualActionPlanItemId) {
  const apiUrl = `/api/mutual-action-plans/${uuid}/items/${mutualActionPlanItemId}`;
  return journeyDeleteJson(apiUrl);
}

function apiGetVisitorJourneys(organizationId, sessionUuid) {
  const apiUrl = `/api/organizations/${organizationId}/visitors/${sessionUuid}/journeys`;

  return journeyGetJson(apiUrl);
}

function apiJourneyBrainAsk(action, context = {}) {
  const apiUrl = `/api/journey-brain/ask`;

  return journeyPostJson(apiUrl, { action, context });
}

function apiJourneyBrainImage(action, context = {}) {
  const apiUrl = '/api/journey-brain/image';

  return journeyPostJson(apiUrl, { action, context });
}

function apiNeueDispatchAction(context, actions) {
  const apiUrl = '/api/neue/dispatch';
  return journeyPostJson(apiUrl, { context, actions });
}

function apiRegenerateBlockText(params) {
  const apiUrl = '/api/journey-brain/topic/regenerate-block-text';
  return journeyPostJson(apiUrl, params);
}

function apiAIGenerateOutline(params) {
  const apiUrl = '/api/journey-brain/topic/generate-outline';
  return journeyPostJson(apiUrl, params);
}

function apiAIGenerateSection(params) {
  const apiUrl = '/api/journey-brain/topic/generate-section';
  return journeyPostJson(apiUrl, params);
}

function apiAIGenerateImagePrompt(params) {
  const apiUrl = '/api/journey-brain/topic/generate-image-prompt';
  return journeyPostJson(apiUrl, params);
}

function apiAIGenerateImagesStyle2(params) {
  const apiUrl = '/api/journey-brain/topic/generate-images-style-2';
  return journeyPostJson(apiUrl, params);
}

function apiAISummarizeWebiste(params) {
  const apiUrl = '/api/journey-brain/summarize-website';
  return journeyPostJson(apiUrl, params);
}

function apiAIJourneyFromContent(params) {
  const apiUrl = '/monet/journey-from-content';
  return journeyPostJson(apiUrl, params);
}

function apiAIJourneyFromFile(params) {
  const apiUrl = '/monet/journey-from-file';
  return journeyPostJson(apiUrl, params);
}

function apiIsContentTooLong(params) {
  const apiUrl = '/monet/is-content-too-long';
  return journeyPostJson(apiUrl, params);
}

function apiGeneratePdf({ uuid, ...params }) {
  const apiUrl = `/api/journeys/${uuid}/pdf`;
  return journeyGetJson(apiUrl, params);
}

function apiESignatureSellerSign({ uuid, ...params }) {
  const apiUrl = `/api/e-signature/${uuid}/seller-sign`;
  console.log(params);
  return journeyPostJson(apiUrl, params);
}

function apiESignatureRecipientSign({ uuid, ...params }) {
  const apiUrl = `/api/e-signature/${uuid}/recipient-sign`;
  console.log(params);
  return journeyPostJson(apiUrl, params);
}

function apiESignatureMarkSellerSignatureAsPending({ uuid }) {
  const apiUrl = `/api/e-signature/${uuid}/mark-seller-signature-as-pending`;
  return journeyPostJson(apiUrl);
}

function apiEsignatureMarkRecipientSignatureAsPending({ uuid }) {
  const apiUrl = `/api/e-signature/${uuid}/mark-recipient-signature-as-pending`;
  return journeyPostJson(apiUrl);
}

function apiValidateBypass(params) {
  const apiUrl = '/api/access/validate-bypass';
  return journeyPostJson(apiUrl, params);
}

function apiGetSubscriptionPlans(orgId) {
  const apiUrl = `/api/organization/${orgId}/subscription/plans`;
  return journeyGetJson(apiUrl);
}

export {
  apiUpdateUser,
  apiGetAllSessions,
  apiGetSessionReplay,
  apiToggleContentLibrary,
  apiGetAllJourneysOverviewInsights,
  apiUpdateUserPassword,
  apiAwsGetUploadCredentials,
  apiGetAllJourneysQuickSearch,
  apiGetAllJourneys,
  apiGetTopJourneysInsights,
  apiGetAllOrganizationTags,
  apiCreateOrganizationTag,
  apiAddTagToJourney,
  apiRemoveTagFromJourney,
  apiGetJourneyTags,
  apiGetJourneysByTags,
  apiGetJourneyEngagements,
  apiGetSubscriptionPlans,
  apiGetJourneyInsights,
  apiGetJourneyChatbotInsights,
  apiSearchJourneyVisitors,
  apiCreateJourneyAlias,
  apiGetJourneyAliases,
  apiUpdateJourneyAlias,
  apiDeleteJourneyAlias,
  apiUpdateJourneyThumbnail,
  apiGenerateCommsTokenByUserInfo,
  apiGetJourneyBySlug,
  apiCreateJourney,
  apiGetCommsInbox,
  apiGetCommsInboxUnreadCount,
  apiGetCommsInboxToken,
  apiGetJourney,
  apiGetTagsByJourney,
  apiPostJourneyInteractionOpen,
  apiUpdateJourney,
  apiUpdateOrganizationSettings,
  apiDeleteJourney,
  apiDuplicateJourney,
  apiCreateBlankJourney,
  apiCreateNeueBlankJourney,
  apiUnsubscribeComments,
  apiGetJourneyThumbnailUrl,
  apiCreateCommsChannel,
  apiGetCommsChannel,
  apiGetWorkspaces,
  apiRevokeUser,
  apiGetWorkspace,
  apiCreateWorkspace,
  apiUpdateWorkspace,
  apiDeleteWorkspace,
  apiGetWorkspaceJourneys,
  apiGetAllNodes,
  apiGetRecentNodes,
  apiCreateOrUpdateNode,
  apiGetPlayerCommsToken,
  apiUpdateDocumentContent,
  apiCreateDocumentBlock,
  apiUpdateDocumentBlock,
  apiDeleteDocumentBlock,
  apiReorderDocumentBlock,
  apiGetPersonalizedVideos,
  apiAddProspectLogo,
  apiGetProspectLogo,
  apiCreateStep,
  apiDeleteStep,
  apiUpdateStep,
  apiReorderStep,
  apiAddNodeToJourney,
  apiRemoveNodeFromJourney,
  apiReplaceNodeInJourney,
  apiRefreshNodeThumbnail,
  apiDeleteJourneyNode,
  apiAddNodeToSynced,
  apiDetachNodeFromSynced,
  apiUnlockJourney,
  apiUnlockJourneyByToken,
  apiRequestAccessToJourney,
  apiSendJourney,
  apiCreateEmailVerification,
  apiVerifyEmail,
  apiGetCurrentUser,
  apiGetUserChangelog,
  apiGetOrganizations,
  apiUpdateOrganizationBranding,
  apiUpdateOrganization,
  apiSwitchOrganization,
  apiGetOrganizationUsers,
  apiGetOrganizationInvites,
  apiSendOrganizationInvite,
  apiResendOrganizationInvite,
  apiDeleteOrganizationInvite,
  apiGetJourneyTemplate,
  apiGetJourneyTemplates,
  apiGetJourneyTemplateCategories,
  apiGetOrganizationTemplates,
  apiCreateOrganizationTemplateFromJourney,
  apiDeleteOrganizationTemplate,
  apiCreateJourneyFromTemplate,
  apiGetDestinations,
  apiOrganizationSmartSearch,
  apiGetUserPersonas,
  apiGetSuggestedTemplates,
  apiOauthAuthorize,
  apiGetUserIntegrations,
  apiGetUserAccessTokens,
  apiRevokeAccessToken,
  apiGetUserApiKeys,
  apiCreateUserApiKey,
  apiUpdateUserApiKey,
  apiDeleteUserApiKey,
  apiGetOrganizationParagonToken,
  apiCreateOrganisation,
  apiGetJourneyOverviewEngagements,
  apiGetNode,
  apiGetAllContents,
  apiCreateContent,
  apiUpdateContent,
  apiGetContent,
  apiGetContentUsage,
  apiUploadContent,
  apiGenerateContentTranscript,
  apiDeleteContent,
  apiDeleteContentByUuid,
  apiUpdateNodeContent,
  apiGetUserIntegrationByProvider,
  apiGetPublicApiKeys,
  apiGetGongObjects,
  apiGetNotionDoc,
  apiGetNotionPublicPageData,
  apiDeactivateOrganization,
  apiGetEmbedDetailsFromUrl,
  apiExportJourneyEmails,
  apiGetJourneyByAlias,
  apiUpdateStepNode,
  apiSendSessionEventsToServer,
  apiFollowJourney,
  apiUnfollowJourney,
  apiSwitchOrganizationBySlug,
  apiAddOrganizationAction,
  apiAddUserAction,
  apiGetUserActions,
  apiCreateNodeAnnotation,
  apiUpdateNodeAnnotation,
  apiDeleteNodeAnnotation,
  apiCreateVideoAsset,
  apiGetThumbnailEmbedUrl,
  apiGetSubscription,
  apiGetInvoices,
  apiGetUserJourneys,
  apiDowngradeSubscription,
  apiCancelSubscription,
  apiSubscribeMonthly,
  apiCheckIfNdaSigned,
  apiSignNda,
  apiGetAllNdaSignatures,
  apiGetMutualActionPlan,
  apiCreateMutualActionPlan,
  apiUpdateMutualActionPlan,
  apiCreateMutualActionPlanItem,
  apiUpdateMutualActionPlanItem,
  apiDeleteMutualActionPlanItem,
  apiUpdateMutualActionPlanItemCompleted,
  apiGetVisitorJourneys,
  apiGetJourneyInsightsByContent,
  apiGetJourneyDocumentInsights,
  apiGetContentInsights,
  apiGetContentDocumentInsights,
  apiGetAllJourneysInsights,
  apiJourneyBrainAsk,
  apiJourneyBrainImage,
  apiNeueDispatchAction,
  apiRegenerateBlockText,
  apiAIGenerateOutline,
  apiAIGenerateSection,
  apiAIGenerateImagesStyle2,
  apiAIGenerateImagePrompt,
  apiAISummarizeWebiste,
  apiAIJourneyFromContent,
  apiAIJourneyFromFile,
  apiIsContentTooLong,
  apiGeneratePdf,
  apiESignatureSellerSign,
  apiESignatureMarkSellerSignatureAsPending,
  apiEsignatureMarkRecipientSignatureAsPending,
  apiESignatureRecipientSign,
  apiValidateBypass,
  apiHubSpotSync,
  apiCreateMergeFields,
  apiGetContentTags,
  apiAddTagToContent,
  apiRemoveTagFromContent,
  apiBulkDeleteContents,
  apiBulkDeleteJourneys,
};
