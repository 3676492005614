import React from 'react';
import { useVisibleDuration } from '../insights/use-visible-duration';
import { usePlayerStore } from './player-store';
import { MemoizedNeuePlayerBlocksView } from './blocks-view';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';

export const NeuePlayerBlocksViewContainer = () => {
  const sectionLayoutInfos = usePlayerStore((state) => state.layout.sectionLayoutInfos);
  const renderElements = usePlayerStore((state) => state.layout.renderElements);
  const innerActualHeight = usePlayerStore((state) => state.layout.innerActualHeight);
  const innerActualWidth = usePlayerStore((state) => state.layout.innerActualWidth);
  const layoutReady = usePlayerStore((state) => state.layout.layoutReady);
  const playerContentElement = usePlayerStore((state) => state.playerContentElement);
  const innerAreaHeight = usePlayerStore((state) => state.innerAreaHeight);
  const setBlockContentSize = usePlayerStore((state) => state.setBlockContentSize);
  const journeyUUID = usePlayerStore((state) => state.uuid);
  const layoutStage = usePlayerStore((state) => state.layoutStage);
  const scrollToSectionId = usePlayerStore((state) => state.currentSectionId);
  const { layoutMode } = useNeueLayoutMode();
  const { observeElements, disconnect } = useVisibleDuration();

  if (!layoutReady || !playerContentElement) {
    return null;
  }

  return (
    <MemoizedNeuePlayerBlocksView
      // Preserving existing logic
      journeyUUID={journeyUUID}
      width={innerActualWidth || 0}
      height={innerActualHeight || 0}
      playerContentElement={playerContentElement}
      innerAreaHeight={innerAreaHeight || 0}
      observeElements={observeElements}
      disconnectObservers={disconnect}
      sectionLayoutInfos={sectionLayoutInfos}
      renderElements={renderElements}
      layoutComplete={layoutStage === 'ready'}
      onBlockSize={setBlockContentSize}
      layoutMode={layoutMode}
      scrollToSectionId={scrollToSectionId}
    />
  );
};

export const MemoizedNeuePlayerBlocksViewContainer = React.memo(NeuePlayerBlocksViewContainer);
